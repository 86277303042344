'use client'

import { createContext, useContext } from 'react'

import { User } from '../../@types/User'

type ProfileContextType = {
    profile: User | null
    setProfile: (profile: User | null) => void
}

export const ProfileContext = createContext<ProfileContextType>({
    profile: null,
    setProfile: () => {},
})

export const useProfile = () => useContext(ProfileContext)
