'use client'

import {
    SidebarProvider,
    // SidebarTrigger
} from '@/components/ui/sidebar'
import { Toaster } from '@/components/ui/sonner'
import { apiFetch } from '@/lib/utils'
import { ProfileContext } from '@/providers/profile'
import { AlertCircleIcon, AlertTriangleIcon, CheckIcon, InfoIcon, Loader2Icon } from 'lucide-react'
import { usePathname } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'

import { User } from '../../@types/User'

export default function ClientLayout({ children }: { children: React.ReactNode }) {
    const [mounted, setMounted] = useState(false)
    const [profile, setProfile] = useState<User | null>(null)
    const pathname = usePathname()
    const initialProfileFetch = useRef(true)
    const [hasDoneFetch, setHasDoneFetch] = useState(false)

    useEffect(() => {
        setMounted(true)
    }, [])

    useEffect(() => {
        if (initialProfileFetch.current) {
            initialProfileFetch.current = false
            apiFetch('/profile')
                .then(({ status, json }) => {
                    if (status === 200) {
                        console.log('profile', json?.profile)
                        setProfile(json?.profile)
                    } else if (status === 500) {
                        console.error('Failed to fetch profile', { json })
                    }
                    setHasDoneFetch(true)
                })
                .catch((error) => {
                    console.error('Failed to fetch profile', { error })
                    setHasDoneFetch(true)
                })
            return
        }
    }, [pathname])

    // Prevent theme flash by not rendering until mounted
    if (!mounted) {
        return null
    }

    // Check if current path should hide sidebar
    // const shouldHideSidebar =
    //     pathname?.startsWith('/form/preview') || pathname?.startsWith('/entry')

    return (
        <SidebarProvider>
            <ProfileContext.Provider value={{ profile, setProfile }}>
                {/* {!shouldHideSidebar && <AppSidebar />} */}
                <AppLayout>
                    {!hasDoneFetch ? (
                        <div className="flex items-center justify-center h-screen">
                            <Loader2Icon className="w-8 h-8 animate-spin" />
                        </div>
                    ) : (
                        children
                    )}
                    {/* {!shouldHideSidebar ? (
                        <>
                            <div className="flex items-center justify-center fixed bottom-0 w-full text-muted-foreground text-xs backdrop-blur-sm">
                                <CopyrightIcon className="w-4 h-3 mr-1" />
                                2024 TherapyForms Inc - Preview build
                                <Button
                                    variant="muted"
                                    size="icon"
                                    onClick={() => {
                                        setTheme(theme === 'dark' ? 'light' : 'dark')
                                    }}
                                    className="text-muted-foreground h-5 w-5 ml-1"
                                >
                                    {theme === 'dark' ? <Moon /> : <Sun />}
                                </Button>
                            </div>
                        </>
                    ) : null} */}
                </AppLayout>
            </ProfileContext.Provider>
        </SidebarProvider>
    )
}

export function AppLayout({ children }: { children: React.ReactNode }) {
    return (
        <main className="w-full">
            {/* {showTrigger && <SidebarTrigger />} */}
            {children}
            <Toaster
                icons={{
                    success: <CheckIcon />,
                    info: <InfoIcon />,
                    warning: <AlertTriangleIcon />,
                    error: <AlertCircleIcon />,
                    loading: <Loader2Icon />,
                }}
            />
        </main>
    )
}
